


















import Vue from 'vue'

export default Vue.extend({
  name: 'ForgotPasswordSended',
  data () {
    return {
      email: null
    }
  },
  methods: {
    backToMainPage () {
      this.$router.push({ name: 'First' })
    }
  }
})
