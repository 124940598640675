














import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'ForgotPasswordReset',
  data () {
    return {
      email: null,
      token: null,
      password: null,
      confirm_password: null
    }
  },
  mounted () {
    const url = new URL(window.location.href)
    this.email = url.searchParams.get('email')
    try {
      this.token = url.pathname.match(/(\/reset\/)(\S+)/)[2]
    } catch (e) {
      this.addNotification({ body: 'Invalid token field in link', type: 'danger' })
    }
    if (!this.email) {
      this.addNotification({ body: 'Invalid email field in link', type: 'danger' })
    }
    this.token && this.email && this.validateResetToken({
      email: this.email,
      token: this.token
    }).catch(err => {
      if (err.response && err.response.data && err.response.data.errors) {
        this.addNotification({ body: err.response.data.errors[0].detail[0], type: 'danger' })
      }
      setTimeout(() => {
        this.$router.push({ name: 'SignIn' })
      }, 3000)
    })
  },
  watch: {
    password: 'checkPasswordsEquality',
    confirm_password: 'checkPasswordsEquality'
  },
  methods: {
    ...mapActions('auth', ['sendResetPasswordRequest', 'validateResetToken']),
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),
    checkPasswordsEquality () {
      const { confirmPasswordEl } = this.$refs

      if (this.password !== this.confirm_password) {
        confirmPasswordEl.setCustomValidity(
          'Passwords must match'
        )
      } else {
        confirmPasswordEl.setCustomValidity('')
      }
    },
    backPage () {
      this.$router.back()
    },
    sendRequest () {
      this.sendResetPasswordRequest({
        email: this.email,
        password: this.password,
        password_confirmation: this.confirm_password,
        token: this.token
      })
        .then(response => {
          this.addSuccessNotification('Success! Redirecting to log in page')
          setTimeout(() => {
            this.$router.push({ name: 'SignIn' })
          }, 3000)
        }).catch(e => {
          if (e.response && e.response.data.errors) {
            this.addNotification({ body: e.response.data.errors[0].detail[0], type: 'danger' })
          }
        })
    }
  }
})
