





































import Vue from 'vue'
import { mapActions } from 'vuex'
import LandingInput from '@/components/landings/LandingInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'

export default Vue.extend({
  components: { LandingInput },
  name: 'ForgotPassword',
  data () {
    return {
      email: null,
      emailError: '',
      submitButtonDisabled: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  i18n: {
    messages: messages
  },
  methods: {
    ...mapActions('auth', ['sendForgotPasswordEmail']),
    ...mapActions('notifications', ['addNotification']),
    changeValue (data) {
      this.email = data.value
      this.emailError = ''
    },
    backPage () {
      this.$router.back()
    },
    sendRequest () {
      if (!this.$v.$invalid) {
        this.submitButtonDisabled = true
        this.sendForgotPasswordEmail(this.email)
          .then(() => {
            this.$router.push({ name: 'Forgot Password Sended' })
          }).catch(e => {
            if (!this.email) {
              this.emailError = e.response.data.errors[0].detail[0]
            } else if (e.response && e.response.data.errors) {
              this.addNotification({
                body: e.response.data.errors[0].detail[0],
                type: 'danger'
              })
            }
          }).finally(() => {
            this.submitButtonDisabled = false
          })
      } else {
        this.$v.$touch()
      }
    }
  }
})
